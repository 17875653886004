/** @format */

.valuation_input {
  background: transparent;
  border: 1px solid #cacaca;
  border-radius: 4px;
  padding: 8px 16px;
}
.valuation_input-outline {
  background: transparent;
  border-bottom: 1px solid #cacaca;
  padding: 8px 16px;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
}

.valuation_input:focus {
  outline: none;
  border: 1px solid #000;
}
.valuation_label {
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  display: block;
  color: #000000;
}
.ValuationForm .MuiRadio-colorSecondary.Mui-checked {
  color: #000000;
}
