.financial_input {
  border-radius: 2px;
  font-weight: 200;
  min-width: 94px;
  max-width: 125px;
  text-align: right;
  border: 1px solid #cacaca;
  padding: 6px 12px;
  border-radius: 5px;
}

.tabpanelcontent .css-19kzrtu {
  padding: 0 !important;
}

.tabpanelcontnet2 {
  padding: 0 !important;
}

table.cashflowTable {
  width: 100%;
  border: none;
}

table.cashflowTable thead,
table.cashflowTable th,
table.cashflowTable tr,
table.cashflowTable td {
  border: none;
}

thead.cashflowthead {
  border: none;
  border-bottom: 1px solid black !important;
  text-align: center;
}

thead.cashflowthead th {
  padding-top: 10px;
  padding-bottom: 7px;
  font-size: 18px;
  font-weight: 700;
}

thead.cashflowthead th p {
  padding-top: 3px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
}

tbody.cashflowthead {
  border: none;
}

tbody.cashflowthead th {
  padding: 10px 0;
  padding-left: 35px;
  font-size: 14px;
}

tbody.cashflowthead tr td {
  text-align: center;
  padding: 10px 0;
}

tbody.cashflowthead tr:first-child td,
tbody.cashflowthead tr:first-child th {
  padding-top: 30px;
  width: 20%;
  min-width: 180px;
}

.balanceSheetmain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.balanceSheetmain hr {
  border: 1.3px solid #757575;
  margin: 0;
}

.balanceSheetmain h1 {
  font-size: 18px;
  font-weight: 700;
}

.assetsmain,
.liabilitymain {
  width: 48%;
  padding: 15px;
}

.balanceSheet {
  margin-top: 25px;
  width: 100%;
}

.balanceSheet div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.balanceSheetmain .valuation_input_label {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: black !important;
  width: 39%;
}

.balanceSheetmain .valuation_input {
  width: 45%;
  text-align: left;
  padding-left: 10px !important;
  color: black !important;
  border: 1px solid #757575 !important;
}

.financial-main-tabs {
  background: white;
}

.financial-main-tabpanel {
  margin-top: 0;
  background: white;
  padding-top: 15px !important;
}

.valuation_drawer_info_root {
  min-width: 350px;
  width: 350px;
}

.valuation_drawer_info_head {
  background-color: rgb(201 240 255 / 30%);
  padding: 12px 16px;
}

.valuation_drawer_info_main {
  padding: 24px;
  height: fit-content;
  overflow: hidden;
  overflow-y: auto;
}
