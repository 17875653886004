.header {
  height: 100vh;
  position: relative;
  /* background-image: linear-gradient(
    to right bottom,
    #e8d7dd,
    #e8d9e1,
    #e7dce5,
    #e7dee9,
    #e6e1ec,
    #e5e2ed,
    #e5e4ee,
    #e4e5ef,
    #e3e5ef,
    #e2e5f0,
    #e0e6f0,
    #dfe6f0
  ); */
}
.header-text {
  font-weight: 700;
  font-size: 3rem;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
}

.header-text_tagline {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #585858;
}

.loop_span {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #cacaca;
  margin: 0 1rem;
}
