.home_benefits_root {
  margin-bottom: 48px;
}
.home_benefits_images img {
  width: 100%;
}
.home_benefits_content_rigth div {
  margin: 36px 0;
  cursor: pointer;
  padding-left: 32px;
}
.home_benefits_content_rigth .disable {
  color: #afafaf;
}
.home_benefits_content_rigth h2 {
  font-family: League Spartan;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.home_benefits_content_rigth p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.border_active {
  border-left: 8px solid #870015;
  border-radius: 4px;
}
