.section_info {
  text-align: center;
  padding: 180px 0;
}

.section_info--header {
  font-weight: 700;
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #123769;
  margin-bottom: 32px;
}

.section_info--text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #222222;
}

.section_break {
  background: linear-gradient(
    270deg,
    rgba(23, 18, 40, 0) 0%,
    #171228 49.07%,
    rgba(23, 18, 40, 0) 100.59%,
    rgba(23, 18, 40, 0) 100.59%
  );
  opacity: 0.5;
  border-radius: 100px;
  width: 940px;
  height: 2px;
  margin: 0 auto;
}

.home {
  position: relative;
}
.home_moving {
  /* position: absolute;
  height: 100vh;
  overflow-y: scroll; */
  background-image: linear-gradient(
    to right bottom,
    #e8d7dd,
    #e8d9e1,
    #e7dce5,
    #e7dee9,
    #e6e1ec,
    #e5e2ed,
    #e5e4ee,
    #e4e5ef,
    #e3e5ef,
    #e2e5f0,
    #e0e6f0,
    #dfe6f0
  );
}
.el1,
.el2 {
  position: fixed;
  z-index: -2;
}
.el1 {
  left: -117px;
  top: 0px;

  background: #880015;
  opacity: 0.4;
  filter: blur(350px);
}

.el2 {
  left: 1030px;
  top: 258px;
  background: #123769;
  opacity: 0.5;
  filter: blur(350px);
}

@media (max-width: 1024px) {
  .section_break {
    width: 80%;
  }
  .section_info--text {
    font-family: "Inter";
    font-weight: 500;
    font-size: 1.05rem;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #222222;
  }
}
