.footer {
  width: 100%;
  /* min-height: 350px; */
  background-color: #fafafa;
  position: relative;
}
.company-rights {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 33px;
  background-color: #eaeaea;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.company-rights p {
  margin: 0;
  font-family: "Inter";
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}
.input_newsletter {
  border: 1px solid #9f9f9f;
  border-radius: 4px;
  background: transparent;
  width: 238px;
  height: 32px;
}
.newsletter_btn {
  background: #000000;
  border-radius: 4px;
  padding: 2px 24px;
  color: #fff;
  margin: 0 0.5rem;
}
.footer_item {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #555555;
  margin: 0 10px;
}
@media (max-width: 768px) {
  .footer {
    width: 100%;
    /* min-height: 450px; */
    background-color: #fafafa;
    position: relative;
  }
}
