.checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkbox-checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #cacaca;
}

.checkbox-container input:checked ~ .checkbox-checkmark {
  background-color: #000;
}

.checkbox-checkmark::after {
  content: "";
  position: absolute;
  display: block;
  color: #fff;
}

/* Style the checkmark/indicator */
.checkbox-checkmark::after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.contact_input,
.contact_textarea {
  background: transparent;
  border: 1px solid #222222;
  border-radius: 8px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 20px;
  padding: 10px 18px;
  color: #000;
  margin: 24px 0;
  width: 580px;
}
.contact_textarea {
  height: 128px;
}

@media (max-width: 768px) {
  .contact_input,
  .contact_textarea {
    width: 100%;
    margin: 14px 0;
  }
}
