.works_card {
  /* width: 620px; */
  /* height: 450px; */
  /* border: 3px solid #ffffff; */
  border-radius: 40px;
  box-shadow: 4px 4px 20px rgba(175, 175, 175, 15%);
  padding: 58px 64px;
  background: radial-gradient(
    94.38% 266.14% at 5.62% 12.26%,
    rgba(255, 255, 255, 0.35) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
}

.data_card {
  position: relative;
  border: 3px solid #ffffff;
  border-radius: 40px;
  box-shadow: 4px 4px 20px rgba(175, 175, 175, 15%);
  padding: 58px 64px;
  height: 655px;
}
.data_card--map {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 42px 64px;
}

.data_card--text {
  width: 523px;
  z-index: 10;
}
.data_card--number {
  z-index: 10;
}

@media (max-width: 768px) {
  .works_card {
    width: 100%;
    padding: 58px 44px;
    margin: 1rem 0;
    height: 400px;
  }
  .data_card--text {
    width: 100%;
  }
  .data_card {
    padding: 40px 44px;
    height: 555px;
  }
}
