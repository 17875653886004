.dasboard_payment_root {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -200px;
}
.dasboard_payment_max {
  max-width: 890px;
  width: 100%;
  border-radius: 24px;
}
