.dashNav_root {
  padding: 16px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid #585858;
  filter: drop-shadow(0px 4px 20px rgba(190, 205, 203, 0.1));
  background: linear-gradient(
    89.91deg,
    #051937 0.08%,
    #171228 28.47%,
    #190a1a 54.47%,
    #12040d 73.19%,
    #000000 99.92%
  );
  color: #fff;
}

.dashNav_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.nav-container {
  height: 100%;
}
.active-route {
  position: absolute;
  width: 100%;
  height: 4px;
  background: linear-gradient(
      145.22deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #7f7f7f;
  border-radius: 100px;
  bottom: -24px;
}

@media (max-width: 768px) {
  .dashNav_root {
    padding: 16px 10px;
  }
}
