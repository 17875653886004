.background {
  background: linear-gradient(
    217.93deg,
    #123769 -55.08%,
    rgba(18, 55, 105, 0.7) -55.08%,
    rgba(177, 182, 188, 0.2) 142.39%
  );
  border-radius: 40px 0px 0px 40px;
  width: 100%;
  height: 100vh;
  position: relative;
}

.auth_pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.auth_left_content {
  height: 100vh;
  overflow: auto;
}

.auth_label {
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 18px;
  color: #9f9f9f;
  margin-bottom: 10px;
  display: block;
}

.auth_input {
  width: 427px;
  height: 44px;
  border: 1px solid rgba(122, 122, 122, 0.6);
  border-radius: 8px;
  background: transparent;
  font-size: 1.1rem;
  padding-left: 16px;
}
.auth_input--small {
  width: 202px;
}
.auth_input::placeholder {
  color: rgba(122, 122, 122, 0.5);
}

.auth_btn {
  width: 427px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background: #123769;
  color: #fff;
  font-size: 20px;
  border-radius: 4px;
}

.auth_right {
  z-index: 30;
}

@media (max-width: 500px) {
  .auth_input,
  .auth_btn,
  .auth_input--small {
    width: 100%;
  }
}
