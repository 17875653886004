.currentFunHead {
  width: 100%;
  padding: 30px 0 0 25px;
}
.tabs-content .MuiSvgIcon-root {
  color: #000000;
}

.currentFunHead h1 {
  font-size: 18px;
  font-weight: 700;
}

.currentFunHead p {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}

.currentFunding hr {
  border: 1.3px solid #757575;
  width: 95%;
  margin: 0 auto;
}

.valuation-transaction {
  padding-left: 21px;
  margin-top: 20px;
}

.valuation-transaction:first-child {
  padding-bottom: 10px;

  margin-top: 20px;
}

.valuation-transaction:nth-child(2) div {
  padding: 3px 5px;
}

.valuation-transaction:first-child p {
  margin: 0 !important;
}

.valuation-transaction h1 {
  font-size: 18px;
  font-weight: 600;
}

.valuation_input_label {
  font-weight: 700 !important;
  color: black !important;
  font-size: 15px !important;
  font-family: "Nunito";
  cursor: text !important;
  width: fit-content;
}

/* .mb-3.selectElement {
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: row;
  width: 49%;
} */

.mb-3.selectElement label {
  width: 100%;
  text-align: start;
  font-size: 15px;
  font-weight: 700;
  font-family: "Nunito";
  color: black;
  cursor: text !important;
}

.mb-3.selectElement select {
  padding: 8px !important;
  font-weight: 600;
  font-size: 14px;
  font-family: "Nunito";
}

.valuation-transaction div div p {
  font-size: 14px;
}

table.pastfundingtable {
  border: none;
  width: 100%;
  margin-bottom: 140px;
}

table.pastfundingtable thead,
table.pastfundingtable tr,
table.pastfundingtable th,
table.pastfundingtable td,
table.pastfundingtable tbody {
  border: none;
}
.pastfundingtable .closingDate::-webkit-calendar-picker-indicator {
  filter: invert(0) !important;
}

thead.pastfundingthead tr th {
  padding: 20px 10px;
  text-align: center;
}
thead.pastfundingthead tr,
tbody.pastfundingtbody tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
thead.pastfundingthead tr th,
tbody.pastfundingtbody tr td {
  width: 22%;
}

thead.pastfundingthead tr th:nth-child(5),
tbody.pastfundingtbody tr td:nth-child(5) {
  width: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
}
thead.pastfundingthead tr {
  border-bottom: 1px solid rgba(202, 202, 202, 0.5);
}

tbody.pastfundingtbody tr:first-child td {
  padding: 25px 0px 10px 0;
  text-align: center;
}
tbody.pastfundingtbody tr td {
  padding: 10px 0px 10px 0;
  text-align: center;
}

tbody.pastfundingtbody tr td input {
  max-width: 90%;
  min-width: 80px;
  height: 37px;
  border: 1px solid #cacaca;
  padding: 0 10px;
  border-radius: 5px;
}

.pastfundingtbody button.addrowsbtn {
  background: black;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 50%;
  color: wheat;
  /* font-size: 11px; */
  font-weight: 700;
}
.pastfundingtbody button.addrowsbtn .MuiSvgIcon-root {
  color: white !important;
}
.pastfundingtbody button.addrowsbtn:hover {
  color: black;
}

.pastfundingtbody hr {
  margin: 0;
  padding: 0;
}
.transectionMain {
  padding: 0 6em;
}
.financial-main-tabs .Mui-selected:nth-child(3),
.financial-main-tabs .Mui-selected:nth-child(4) {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #4ad7d1 !important;
}
/* .financial-main-tabs .MuiTabs-flexContainer button {
  border: 1px solid rgba(104, 193, 210, 1) !important;
  background: rgba(104, 193, 210, 0.1) !important;
  border-bottom: 2px solid #4AD7D1 !important;
}
.valuation-root .Mui-selected {
  color: #000 !important;
  border: 2px solid #4AD7D1 !important;
  box-sizing: border-box;
  background-color: #fff !important;
  border-radius: 0 0px 0px 0px;
  border-bottom: 0 !important;
} */
