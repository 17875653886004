.navbar {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  padding-right: 20px;
  position: fixed;
}

.nav_bg {
  background: radial-gradient(
    94.38% 266.14% at 5.62% 12.26%,
    rgba(255, 255, 255, 0.35) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
  backdrop-filter: blur(6px);
}

.nav_elements {
  font-family: "Inter";
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  color: #000000;
}
.nav_element {
  margin: 0 20px;
}
.nav_action {
  font-family: "Inter";
  font-weight: 600;
  cursor: pointer;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 100px;
  padding: 12px 24px;
}

.menu-toggle {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  position: absolute;
  top: 23px;
  right: 14px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: transparent;
}

.small_nav {
  background-color: #f7f9fe;
  position: absolute;
  right: 10px;
  top: 70px;
  height: 25rem;
  width: 15rem;
  border-radius: 10px;
  box-shadow: 4px 4px 20px rgba(175, 175, 175, 15%);
  padding: 1rem 0;
}

.nav_element_small {
  margin: 0.5rem 0;
}
