.timeline {
  position: relative;
  margin: 0 auto;
}

.timeline::after {
  content: "";
  position: absolute;
  width: 2px;
  background-image: repeating-linear-gradient(
      0deg,
      #870015,
      #870015 12px,
      transparent 12px,
      transparent 33px,
      #870015 33px
    ),
    repeating-linear-gradient(
      90deg,
      #870015,
      #870015 12px,
      transparent 12px,
      transparent 33px,
      #870015 33px
    ),
    repeating-linear-gradient(
      180deg,
      #870015,
      #870015 12px,
      transparent 12px,
      transparent 33px,
      #870015 33px
    ),
    repeating-linear-gradient(
      270deg,
      #870015,
      #870015 12px,
      transparent 12px,
      transparent 33px,
      #870015 33px
    );
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
}

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
  position: relative;
}
.left {
  grid-column: 1;
  padding-right: 20px;
}

/* Place the container to the right */
.right {
  grid-column: 2;
  padding-left: 20px;
}

.timeline_list {
  list-style: disc;
  text-align: left;
}
.timeline_list li {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.02em;

  color: #000000;
}
.timeline_number {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -20px;
  max-height: fit-content;
  background-color: #e4e4ee;
  z-index: 100;
}

@media (max-width: 635px) {
  .timeline::after {
    background-image: none;
  }
  .timeline_number {
    display: none;
  }
  .container {
    display: flex;
    flex-direction: column;
  }
  .timeline .content {
    order: 2;
  }
}
@media (max-width: 1000px) {
  .timeline::after {
    background-image: none;
  }
  .timeline_number {
    display: none;
  }
}
