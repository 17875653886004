@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@200;300;400;500;600;700;800;900&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  color: inherit;
}

body {
  font-family: "League Spartan", sans-serif;
  background: #f7f9fe;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f7f9fe;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #870015;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #870015;
}
