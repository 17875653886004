.stepperMain {
  padding-top: 30px;
  width: 70%;
  margin: 0 auto;
}

.stepperMain .MuiSvgIcon-root {
  font-size: 1.8rem;
}

.services-main {
  width: 100%;
  padding: 0;
  height: fit-content;
  margin: 0;
  position: relative;
}

/* .valuationForm {
       display: flex;
       justify-content: center;
       align-items: center;
   } */

.main-tab-content {
  width: 100%;
  padding: 0;
  margin-top: 0;
}

.valuationHeading {
  background: transparent;
  padding: 0;
  color: black;
  display: flex;
  margin-bottom: 12px;
}

.valuation-back {
  margin-right: 17px;
}

.valuation-back button {
  background: transparent;
  outline: none;
  border: none;
}

.valuationHeading h3 {
  text-transform: uppercase;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
}

.tabs-content {
  display: flex;
  width: 100%;
  background: transparent;
  flex-wrap: wrap;
  flex-direction: column;
}

.tabs-content .MuiTabs-flexContainer button {
  font-family: "Nunito";
  width: 25%;
  border-bottom: 2px solid #4ad7d1;
}
.tabs-content .MuiTabs-flexContainer button:nth-child(1) {
  border-radius: 12px 0 0 0;
  border: 1px solid rgba(229, 105, 151, 0.5);
  background: rgba(229, 105, 151, 0.2);
  border-bottom: 2px solid #4ad7d1;
}
.tabs-content .MuiTabs-flexContainer button:nth-child(2) {
  border: 1px solid rgba(189, 151, 203, 0.5);
  background: rgba(189, 151, 203, 0.2);
  border-bottom: 2px solid #4ad7d1;
}
.tabs-content .MuiTabs-flexContainer button:nth-child(3) {
  border: 1px solid rgba(251, 199, 64, 1);
  background: rgba(251, 199, 64, 0.2);
  border-bottom: 2px solid #4ad7d1;
}
.tabs-content .MuiTabs-flexContainer button:nth-child(4) {
  border-radius: 0 12px 0 0;
  border: 1px solid rgba(168, 249, 73, 1);
  background: rgba(168, 249, 73, 0.2);
  border-bottom: 2px solid #4ad7d1;
}

.tabs-content .MuiTabs-flexContainer {
  width: 100%;
  align-items: flex-start;
  display: flex;
}

.questionare-title-exp {
  padding: 15px;
}

.tabpanelcontent {
  width: 100%;
  padding: 10px 0;
  margin: 0;
  min-height: 400px;
  margin-top: 20px;
}

.tabs-questionare-root {
  background: #fff;
  border-radius: 12px;
}

.valuation-root .MuiTabs-indicator {
  background-color: #4ad7d1 !important;
}

.valuation-root .Mui-selected {
  color: #4ad7d1 !important;
}

.valuation-root .Mui-selected {
  color: #000 !important;
  border: 2px solid #4ad7d1 !important;
  box-sizing: border-box;
  background-color: #fff !important;
  border-radius: 0 0px 0px 0px;
  border-bottom: 0 !important;
}

.valuation-root .Mui-selected:first-child {
  border-radius: 12px 0px 0px 0px;
}

.valuation-root .Mui-selected:last-child {
  border-radius: 0 12px 0px 0px;
}

.savenextbtn {
  margin-left: 20px;
  margin-bottom: 20px;
}

.valuation-root.container {
  padding: 0;
  padding-top: 0;
  padding-bottom: 40px;
}

.valuation-root .MuiTabs-indicator {
  left: 0 !important;
  width: 0 !important;
}

.valuation-root .Mui-checked {
  color: #4ad7d1 !important;
}

.valuation-root .jss7 {
  background-color: white !important;
  height: auto !important;
  width: auto;
}
.valuation_equidam_banner {
  padding: 24px;
  text-align: center;
  background: #fff;
  border-radius: 12px;
}

.valuation_equidam_report {
  max-width: 100%;
  margin: 10px 0;
}
.val_infobanner_root {
  display: flex;
  border: 1px solid #68c1d2;
  border-radius: 4px;
  padding: 6px 16px;
  margin-bottom: 16px;
  background-color: #fff;
  align-items: center;
}
.val_infobanner_root img {
  height: 24px;
  width: 24px;
  margin-right: 8px;
}
.cta_lets_start {
  background: #000000;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: #fff;
  outline: none !important;
  border: 0;
}
.cta_lets_start_outline {
  background: #fff;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: #000;
  outline: none !important;
  border: 1px solid #000;
}

.cta_lets_start_disabled {
  background: rgba(0, 0, 0, 0.3);
}

.popover_list_cta {
  transition: all 0.2s ease;
  border: none;
  min-width: 90px;
  padding: 7px;
  background: #fff;
  font-family: var(--Nunito);
  font-size: 13px;
  text-align: left !important;
}
.popover_list_cta:hover {
  background-color: #ededed;
  border-radius: 6px;
}
.popOver-rootTask {
  display: flex;
  flex-direction: column;
  justify-content: flex-end !important;
  align-items: flex-start !important;
  min-width: 100px !important;
}

.valuation_tool_left_root {
  width: 20%;
  max-height: 77vh;
  overflow: hidden;
  overflow-y: auto;
}

.valuation_tool_left_main {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(111, 111, 111, 0.05);
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
}
.val_left_icon_active > svg,
.val_left_icon_active > svg circle {
  fill: #000000;
  transition: all 0.3s linear;
}

.val_left_icon_active > svg > path {
  fill: #fff;
  transition: all 0.3s linear;
}
.valuation_tool_right_main {
  max-height: 77vh;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 80%;
  padding-left: 24px;
}

.valuation_tool_right_main::-webkit-scrollbar-thumb,
.valuation_tool_right_main::-webkit-scrollbar-track {
  display: none;
}

.valuation_tool_left_line,
.valuation_tool_left_line2 {
  height: 32px;
  width: 1px;
  background: rgba(175, 175, 175, 0.5);
  margin-left: 20px;
}

.valuation_tool_left_line2 {
  margin-left: 7px;
}
.unsaved_dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: transparent;
}

.active_dot_save {
  background-color: #000;
}
.current_val {
  border-bottom: 1px solid #afafaf;
  border-top: 1px solid #afafaf;
  padding: 16px 0;
}

.removebtn {
  background: transparent;
  border: none;
  outline: none;
}

.addrowsbtn {
  background: #000000;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 50%;
  color: white;
  font-weight: 700;
  height: 37px;
  width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

form.questinare-form-root {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

form.questinare-form-root > div {
  width: 100%;
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #cacaca50;
}

form.questinare-form-root > div > label {
  max-width: 65%;
}
form.questinare-form-root > div > input {
  width: 35%;
}
.questionare-btn {
  width: 100% !important;
  background: transparent !important;
  margin-top: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}
p.valuation-count {
  position: absolute;
  font-size: 16px;
  right: 14px;
  font-weight: 600;
  color: #000;
}
.valuation-sampleReports .sampleReport {
  background: #404654;
  width: 100%;
  border-radius: 10px;
  color: #fff;
  padding: 20px 0 5px 30px;
  z-index: 10;
  position: relative;
}
.sampleReport h6 {
  text-transform: uppercase;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 3px;
}
.sampleReport p {
  text-transform: capitalize;
  font-size: 12px;
  color: hsla(0, 0%, 100%, 0.7);
}
p.downloadSample {
  color: #36d4c0;
  font-size: 15px;
  text-transform: uppercase;
  margin: 12px 0;
}
.sampleReport a p svg {
  width: 1.5em;
  height: 1.5em;
  fill: #36d4c0;
  margin-right: 7px;
}
.valuation-mainDefVdo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.valuation_banner_01 {
  background: url("../../../Assets/Valuation.svg");
  background-size: 100% auto;
}

.initital_est_modal_left_main {
  min-width: 230px;
  height: 230px;
  background-repeat: no-repeat;
  margin-right: 40px;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 950px) {
  .valuation-root.container {
    padding-top: 50px;
  }
  .valuation_tool_right_main {
    max-height: 100%;
    overflow-y: scroll;
    width: 95%;
    padding: 12px;
    padding-top: 24px;
  }
}

@media (max-width: 600px) {
  .valuation_banner_01 {
    background-size: auto 100%;
    border-radius: 5%;
    margin: 5px;
    padding: 50px 20px;
  }
  .valuation_equidam_banner {
    margin-top: 20px;
  }
  .valuation_archives {
    margin-top: 20px;
  }
}
