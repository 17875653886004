/** @format */

.MuiFormControlLabel-label {
  font-weight: 300 !important;
  font-size: 14px !important;
}

.selectElement option {
  text-transform: capitalize;
}

.zefy_radio .MuiIconButton-label svg {
  color: #000;
}
.MuiAutocomplete-endAdornment {
  top: calc(50% - 16px) !important;
}
.select_label .MuiTypography-root {
  color: #404654 !important;
}
