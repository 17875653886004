@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");


/* Fonts */

.font12{
  font-size: 12px;
}

/* ! Paddings */

.px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

/* ! Margins */
.mt-80 {
  margin-top: 80px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-56 {
  margin-top: 56px;
}
.mt-40 {
  margin-top: 40px;
}
.my-180 {
  margin-top: 180px;
  margin-bottom: 180px;
}
.my-160 {
  margin-top: 160px;
  margin-bottom: 160px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-24 {
  margin-top: 24px;
}

/* margin-padding */

.mt-8 {
  margin-top: 8px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-14 {
  margin-top: 14px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-56 {
  margin-top: 56px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-64 {
  margin-top: 64px !important;
}

.mt-72 {
  margin-top: 72px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mr-14 {
  margin-right: 14px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-6 {
  margin-left: 6px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-48 {
  margin-left: 48px !important;
}

.ml-60 {
  margin-left: 60px !important;
}
.ml-80 {
  margin-left: 80px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb--5 {
  margin-bottom: -5px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-32 {
  padding: 32px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pt-6 {
  padding-top: 6px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 70px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.pr-6 {
  padding-right: 6px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-6 {
  padding-left: 6px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pb-6 {
  padding-bottom: 6px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.p-10 {
  padding: 10px;
}

.p-16 {
  padding: 16px !important;
}

.p-6 {
  padding: 6px;
}

.p-40 {
  padding: 40px !important;
}

.p-8 {
  padding: 8px;
}

/* !Utils */

.heading_xl {
  font-size: 3rem;
  letter-spacing: 0.02em;
}

.heading_l {
  font-size: 2rem; /*  ! 32 px */
  letter-spacing: 0.02em;
}
.heading_m {
  font-size: 1.5rem; /*  ! 24 px */
  letter-spacing: 0.02em;
}

.heading_s {
  font-size: 1.4rem; /*  ! 22.4 px */
  letter-spacing: 0.02em;
}

.heading_xs {
  font-size: 1rem; /*  ! 16 px */
  letter-spacing: 0.02em;
}

.body2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.body3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  display: flex;
  align-items: center;
}

.title2 {
  font-family: "League Spartan", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
}

.title3 {
  font-family: "League Spartan", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
}

.caption {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.caption10 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
}
.caption36 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 17px;
  letter-spacing: 0.02em;
}

.caption12 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.overline {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}

.text_xs {
  font-size: 0.875rem; /*  ! 14 px */
  letter-spacing: 0.02em;
}

.text_s {
  font-size: 1.125rem; /*  ! 20 px */
  letter-spacing: 0.02em;
}

.text_sl {
  font-size: 1.3rem; /*  ! 20 px */
  letter-spacing: 0.02em;
}

.text_xl {
  font-size: 5rem; /*  ! 80 px */
  letter-spacing: 0.02em;
}
.text_l {
  font-size: 3.75rem; /*  ! 60 px */
  letter-spacing: 0.02em;
}

.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}

.font-inter {
  font-family: "Inter" !important;
}

.lh-19 {
  line-height: 19px;
}
.lh-22 {
  line-height: 22px;
}

.lh-90 {
  line-height: 90px;
}
.lh-36 {
  line-height: 36px;
}
.lh-140 {
  line-height: 140%;
}
.lh-150 {
  line-height: 150%;
}

.color_primary {
  color: #870115;
}

.color_secondary {
  color: #123769 !important;
}

.color_accent {
  color: #222222;
}

.color_dark {
  color: #000000;
}

.color_light_v1 {
  color: #585858;
}
.color_light_v2 {
  color: #9f9f9f;
}
.color_light_v3 {
  color: #afafaf;
}
.color_light_v4 {
  color: #cacaca;
}

.color_grey_46 {
  color: #404654;
}
.color_white {
  color: #fff !important;
}
.header_btn {
  background: #222222;
  border-radius: 100px;
  padding: 20px 66px;
  color: #ffff;
  font-weight: 600;
  font-size: 24px;
  margin-top: 2em;
}

.header_btn--small {
  background: #222222;
  border-radius: 100px;
  padding: 8px 28px;
  color: #ffff;
  font-weight: 600;
  font-size: 16px;
  margin-top: 2em;
}

.flex-responsive {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor_p {
  cursor: pointer;
}

.input_label {
  font-weight: 500;
  font-size: 1rem;
  line-height: 18px;
  color: #9f9f9f;
  margin-bottom: 10px;
  display: block;
  font-family: Inter;
}

.input {
  /* width: 427px; */
  height: 44px;
  border: 1px solid rgba(122, 122, 122, 0.6);
  border-radius: 8px;
  background: transparent;
  font-size: 1rem;
  padding-left: 16px;
  font-family: Inter;
}

.submit_btn {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background: #212121;
  color: #fff;
  font-size: 20px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  border: 0;
}
.submit_btn-outlined {
  /* width: 100%; */
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border: 1px solid #212121;
  font-size: 20px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  background: #fff;
}

.submit_btn_disabled {
  background: rgba(33, 33, 33, 0.8);
}

.cta-small {
  background: #222222;
  border-radius: 4px;
  padding: 13px 24px;
  color: #fff;
}
.white-background {
  background: #fff !important;
  box-shadow: 0 4px 20px rgba(111, 111, 111, 0.051);
}
@media (max-width: 768px) {
  .heading_xl {
    font-size: 1.5rem;
    letter-spacing: 0.02em;
  }
  .heading_l {
    font-size: 1.2rem;
  }
  .heading_m {
    font-size: 1rem;
    letter-spacing: 0.02em;
  }
  .heading_s {
    font-size: 0.9rem;
    letter-spacing: 0.02em;
  }

  .header_btn {
    background: #222222;
    border-radius: 100px;
    padding: 16px 56px;
    color: #ffff;
    font-weight: 600;
    font-size: 20px;
    margin-top: 2em;
  }
  .flex-responsive {
    flex-direction: column;
  }
}

@media (max-width: 1024px) {
  /* styles here will apply to devices with a maximum width of 768px */
}

@media (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0px !important;
  }

  .mt-sm-4 {
    margin-top: 4px !important;
  }

  .mt-sm-8 {
    margin-top: 8px !important;
  }

  .mt-sm-6 {
    margin-top: 6px !important;
  }

  .mt-sm-10 {
    margin-top: 10px !important;
  }

  .mt-sm-12 {
    margin-top: 12px !important;
  }

  .mt-sm-14 {
    margin-top: 14px !important;
  }

  .mt-sm-16 {
    margin-top: 16px !important;
  }

  .mt-sm-20 {
    margin-top: 20px !important;
  }

  .mt-sm-24 {
    margin-top: 24px !important;
  }

  .mt-sm-30 {
    margin-top: 30px !important;
  }

  .mt-sm-32 {
    margin-top: 32px !important;
  }

  .mt-sm-36 {
    margin-top: 36px !important;
  }

  .mt-sm-40 {
    margin-top: 40px !important;
  }

  .mt-sm-48 {
    margin-top: 48px !important;
  }

  .mt-sm-50 {
    margin-top: 50px !important;
  }

  .mt-sm-56 {
    margin-top: 56px !important;
  }

  .mt-sm-60 {
    margin-top: 60px !important;
  }

  .mt-sm-64 {
    margin-top: 64px !important;
  }

  .mt-sm-72 {
    margin-top: 72px !important;
  }

  .mr-sm-0 {
    margin-right: 0px !important;
  }

  .mr-sm-4 {
    margin-right: 4px !important;
  }

  .mr-sm-40 {
    margin-right: 40px !important;
  }

  .mr-sm-8 {
    margin-right: 8px !important;
  }

  .mr-sm-6 {
    margin-right: 6px !important;
  }

  .mr-sm-10 {
    margin-right: 10px !important;
  }

  .mr-sm-12 {
    margin-right: 12px !important;
  }

  .mr-sm-14 {
    margin-right: 14px !important;
  }

  .mr-sm-16 {
    margin-right: 16px !important;
  }

  .mr-sm-20 {
    margin-right: 20px !important;
  }

  .mr-sm-24 {
    margin-right: 24px !important;
  }

  .mr-sm-32 {
    margin-right: 32px !important;
  }

  .ml-sm-0 {
    margin-left: 0px !important;
  }

  .ml-sm-8 {
    margin-left: 8px !important;
  }

  .ml-sm-6 {
    margin-left: 6px !important;
  }

  .ml-sm-10 {
    margin-left: 10px !important;
  }

  .ml-sm-12 {
    margin-left: 12px !important;
  }

  .ml-sm-16 {
    margin-left: 16px !important;
  }

  .ml-sm-24 {
    margin-left: 24px !important;
  }

  .ml-sm-32 {
    margin-left: 32px !important;
  }

  .ml-sm-20 {
    margin-left: 20px !important;
  }

  .ml-sm-40 {
    margin-left: 40px !important;
  }

  .ml-sm-48 {
    margin-left: 48px !important;
  }

  .ml-sm-60 {
    margin-left: 60px !important;
  }

  .mb-sm-0 {
    margin-bottom: 0px !important;
  }

  .mb-sm-8 {
    margin-bottom: 8px !important;
  }

  .mb-sm-6 {
    margin-bottom: 6px !important;
  }

  .mb-sm-10 {
    margin-bottom: 10px !important;
  }

  .mb-sm-12 {
    margin-bottom: 12px !important;
  }

  .mb-sm-16 {
    margin-bottom: 16px !important;
  }

  .mb-sm-20 {
    margin-bottom: 20px !important;
  }

  .mb-sm-24 {
    margin-bottom: 24px !important;
  }

  .mb-sm-32 {
    margin-bottom: 32px !important;
  }

  .mb-sm-40 {
    margin-bottom: 40px !important;
  }

  .mb-sm--5 {
    margin-bottom: -5px !important;
  }
}


@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0px !important;
  }

  .mt-md-4 {
    margin-top: 4px !important;
  }

  .mt-md-8 {
    margin-top: 8px !important;
  }

  .mt-md-6 {
    margin-top: 6px !important;
  }

  .mt-md-10 {
    margin-top: 10px !important;
  }

  .mt-md-12 {
    margin-top: 12px !important;
  }

  .mt-md-14 {
    margin-top: 14px !important;
  }

  .mt-md-16 {
    margin-top: 16px !important;
  }

  .mt-md-20 {
    margin-top: 20px !important;
  }

  .mt-md-24 {
    margin-top: 24px !important;
  }

  .mt-md-30 {
    margin-top: 30px !important;
  }

  .mt-md-32 {
    margin-top: 32px !important;
  }

  .mt-md-36 {
    margin-top: 36px !important;
  }

  .mt-md-40 {
    margin-top: 40px !important;
  }

  .mt-md-48 {
    margin-top: 48px !important;
  }

  .mt-md-50 {
    margin-top: 50px !important;
  }

  .mt-md-56 {
    margin-top: 56px !important;
  }

  .mt-md-60 {
    margin-top: 60px !important;
  }

  .mt-md-64 {
    margin-top: 64px !important;
  }

  .mt-md-72 {
    margin-top: 72px !important;
  }

  .mr-md-0 {
    margin-right: 0px !important;
  }

  .mr-md-4 {
    margin-right: 4px !important;
  }

  .mr-md-40 {
    margin-right: 40px !important;
  }

  .mr-md-8 {
    margin-right: 8px !important;
  }

  .mr-md-6 {
    margin-right: 6px !important;
  }

  .mr-md-10 {
    margin-right: 10px !important;
  }

  .mr-md-12 {
    margin-right: 12px !important;
  }

  .mr-md-14 {
    margin-right: 14px !important;
  }

  .mr-md-16 {
    margin-right: 16px !important;
  }

  .mr-md-20 {
    margin-right: 20px !important;
  }

  .mr-md-24 {
    margin-right: 24px !important;
  }

  .mr-md-32 {
    margin-right: 32px !important;
  }

  .ml-md-0 {
    margin-left: 0px !important;
  }

  .ml-md-8 {
    margin-left: 8px !important;
  }

  .ml-md-6 {
    margin-left: 6px !important;
  }

  .ml-md-10 {
    margin-left: 10px !important;
  }

  .ml-md-12 {
    margin-left: 12px !important;
  }

  .ml-md-16 {
    margin-left: 16px !important;
  }

  .ml-md-24 {
    margin-left: 24px !important;
  }

  .ml-md-20 {
    margin-left: 20px !important;
  }

  .ml-md-32 {
    margin-left: 32px !important;
  }

  .ml-md-40 {
    margin-left: 40px !important;
  }

  .ml-md-48 {
    margin-left: 48px !important;
  }

  .ml-md-60 {
    margin-left: 40px !important;
  }

  .mb-md-0 {
    margin-bottom: 0px !important;
  }

  .mb-md-8 {
    margin-bottom: 8px !important;
  }

  .mb-md-6 {
    margin-bottom: 6px !important;
  }

  .mb-md-10 {
    margin-bottom: 10px !important;
  }

  .mb-md-12 {
    margin-bottom: 12px !important;
  }

  .mb-md-16 {
    margin-bottom: 16px !important;
  }

  .mb-md-20 {
    margin-bottom: 20px !important;
  }

  .mb-md-24 {
    margin-bottom: 24px !important;
  }

  .mb-md-32 {
    margin-bottom: 32px !important;
  }

  .mb-md-40 {
    margin-bottom: 40px !important;
  }

  .mb-md--5 {
    margin-bottom: -5px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg-4 {
    margin-top: 4px !important;
  }

  .mt-lg-0 {
    margin-top: 0px !important;
  }

  .mt-lg-8 {
    margin-top: 8px !important;
  }

  .mt-lg-6 {
    margin-top: 6px !important;
  }

  .mt-lg-10 {
    margin-top: 10px !important;
  }

  .mt-lg-12 {
    margin-top: 12px !important;
  }

  .mt-lg-14 {
    margin-top: 14px !important;
  }

  .mt-lg-16 {
    margin-top: 16px !important;
  }

  .mt-lg-20 {
    margin-top: 20px !important;
  }

  .mt-lg-24 {
    margin-top: 24px !important;
  }

  .mt-lg-30 {
    margin-top: 30px !important;
  }

  .mt-lg-32 {
    margin-top: 32px !important;
  }

  .mt-lg-36 {
    margin-top: 36px !important;
  }

  .mt-lg-40 {
    margin-top: 40px !important;
  }

  .mt-lg-48 {
    margin-top: 48px !important;
  }

  .mt-lg-50 {
    margin-top: 50px !important;
  }

  .mt-lg-56 {
    margin-top: 56px !important;
  }

  .mt-lg-60 {
    margin-top: 60px !important;
  }

  .mt-lg-64 {
    margin-top: 64px !important;
  }

  .mt-lg-72 {
    margin-top: 72px !important;
  }

  .mr-lg-0 {
    margin-right: 0px !important;
  }

  .mr-lg-4 {
    margin-right: 4px !important;
  }

  .mr-lg-40 {
    margin-right: 40px !important;
  }

  .mr-lg-8 {
    margin-right: 8px !important;
  }

  .mr-lg-6 {
    margin-right: 6px !important;
  }

  .mr-lg-10 {
    margin-right: 10px !important;
  }

  .mr-lg-12 {
    margin-right: 12px !important;
  }

  .mr-lg-14 {
    margin-right: 14px !important;
  }

  .mr-lg-16 {
    margin-right: 16px !important;
  }

  .mr-lg-20 {
    margin-right: 20px !important;
  }

  .mr-lg-24 {
    margin-right: 24px !important;
  }

  .mr-lg-32 {
    margin-right: 32px !important;
  }

  .ml-lg-0 {
    margin-left: 0px !important;
  }

  .ml-lg-8 {
    margin-left: 8px !important;
  }

  .ml-lg-6 {
    margin-left: 6px !important;
  }

  .ml-lg-10 {
    margin-left: 10px !important;
  }

  .ml-lg-12 {
    margin-left: 12px !important;
  }

  .ml-lg-16 {
    margin-left: 16px !important;
  }

  .ml-lg-24 {
    margin-left: 24px !important;
  }

  .ml-lg-20 {
    margin-left: 20px !important;
  }

  .ml-lg-32 {
    margin-left: 32px !important;
  }

  .ml-lg-40 {
    margin-left: 40px !important;
  }

  .ml-lg-48 {
    margin-left: 48px !important;
  }

  .ml-lg-60 {
    margin-left: 40px !important;
  }

  .mb-lg-0 {
    margin-bottom: 0px !important;
  }

  .mb-lg-8 {
    margin-bottom: 8px !important;
  }

  .mb-lg-6 {
    margin-bottom: 6px !important;
  }

  .mb-lg-10 {
    margin-bottom: 10px !important;
  }

  .mb-lg-12 {
    margin-bottom: 12px !important;
  }

  .mb-lg-16 {
    margin-bottom: 16px !important;
  }

  .mb-lg-20 {
    margin-bottom: 20px !important;
  }

  .mb-lg-24 {
    margin-bottom: 24px !important;
  }

  .mb-lg-32 {
    margin-bottom: 32px !important;
  }

  .mb-lg-40 {
    margin-bottom: 40px !important;
  }

  .mb-lg--5 {
    margin-bottom: -5px !important;
  }
}
