.dashboard01_home .input1 {
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 4px;
  padding: 5px 16px;
  font-family: Nunito;
  outline: none;
  width: 100%;
}
.dashboard01_home .input1::placeholder {
  color: #cacaca;
}
.dashboard01_home .input1:focus-visible {
  border: 2px solid #000000;
}

.valuation_company_info .input2 {
  background: #ffffff;
  border: none;
  border-bottom: 1px solid #cacaca;
  padding: 5px;
  font-family: Nunito;
  width: 100%;
  border-radius: 0;
}
.valuation_company_info .input2::placeholder {
  color: #cacaca;
}
.valuation_company_info .input2:focus-visible {
  border-bottom: 2px solid #000000;
}
.valuation_company_info .pastfundingtable th {
  text-align: left;
}
.valuation_company_info .competitors_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.valuation_company_info .competitors_row td {
  font-family: Nunito;
  text-align: left;
  padding: 8px 16px;
}
