.dasboard_main {
  background: linear-gradient(
    89.91deg,
    #051937 0.08%,
    #171228 28.47%,
    #190a1a 54.47%,
    #12040d 73.19%,
    #000000 99.92%
  );
  color: white;
  height: 50vh;
}

.dashboard_head {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard_head_content {
  max-width: 890px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dasboard_reports_root {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -55px;
}

.dasboard_reports_max {
  max-width: 890px;
  width: 100%;
}

.create-report-Cta {
  background: linear-gradient(
    270.63deg,
    rgba(246, 246, 254, 0.25) 2.26%,
    rgba(241, 241, 253, 0.25) 33.27%,
    rgba(196, 202, 238, 0.25) 95.9%
  );
  border-radius: 4px;
  padding: 10px 24px;
  color: white;
  font-family: "League Spartan";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: center;
  border: 1px solid #d7d7d7;
}

.val_card_progress_circle {
  background: #ffffff;
  border: 0.5px solid #404654;
  min-height: 24px;
  min-width: 24px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.val_card_progress_check {
  background: #404654;
  border: 0.5px solid #404654;
  min-height: 20px;
  min-width: 20px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.val_card_progress_line {
  width: 4px;
  height: 16px;
  background: #eaeaea;
  margin-left: 10px;
}

.generated_val_cover > img {
  width: 100%;
}

.report_block {
  background-color: white;
  box-shadow: 0px 4px 20px rgba(111, 111, 111, 0.05);
  border-radius: 8px;
}

@media (max-width: 768px) {
  .dashboard_head_content {
    flex-direction: column;
    align-items: start;
    padding: 0 0.7rem;
  }
  .dasboard_reports_root {
    padding: 0 0.7rem;
  }
}
