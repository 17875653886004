.pricing_card {
  background: radial-gradient(
    94.38% 266.14% at 5.62% 12.26%,
    rgba(255, 255, 255, 0.35) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
  border: 1.5px solid #ffffff;
  border-radius: 12px;
  box-shadow: 4px 4px 20px rgba(175, 175, 175, 15%);
}

.pricing_card--main {
  padding: 35px 0;
  margin: 0 1rem;
  width: 468px;
}

.pricing_desc_card {
  width: 468px;
  /* height: 130px; */
  padding: 24px;
  text-align: left;
  margin: 1rem 0;
}

.pricing_feature_rows {
  padding: 4rem 6rem;
  max-width: 100%;
}

.pricing_desc_card h1 {
  text-transform: uppercase;
}

.pricing_discount {
  background: linear-gradient(
    180deg,
    rgba(135, 0, 21, 0.15) 13.43%,
    rgba(75, 0, 12, 0.15) 100%
  );
  padding: 8px 16px;
}

.btn_pricing {
  background: radial-gradient(
    134.26% 380.77% at -22.21% -10%,
    rgba(135, 0, 21, 0.1) 0%,
    rgba(234, 234, 234, 0.101042) 99.99%,
    rgba(18, 55, 105, 0.1) 100%
  );
  backdrop-filter: blur(6px);

  padding: 13px 24px;

  border-radius: 100px;
}

@media (max-width: 768px) {
  .pricing_desc_card {
    width: 100%;
  }
  .pricing_feature_rows {
    padding: 4rem 0.6rem;
  }
  .pricing_card--main {
    width: 100%;
  }
}
