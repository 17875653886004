.dasboard_payment_history_root {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -55px;
}
.dasboard_payment_history_max {
  max-width: 890px;
  width: 100%;
  border-radius: 8px;
}
